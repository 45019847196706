import { createRoot } from "react-dom/client";
import { loadRuntimeConfig } from "./runtimeConfig/loader";

/**
 * Wait for runtimeConfig to be available before bootstrapping the app.
 * This allows us to use non-optional types for RuntimeConfig.
 */
loadRuntimeConfig()
  .then(() => import("./App"))
  .then(({ App }) => {
    const root = createRoot(document.getElementById("root")!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    root.render(<App />);
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);

    window.location.replace("/failed-to-load-app.html");
  });
